import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "headers"
    }}>{`Headers`}</h1>
    <p>{`To maintain a page's proper `}<strong parentName="p">{`D*`}</strong>{`ocument `}<strong parentName="p">{`O`}</strong>{`bject `}<strong parentName="p">{`M`}</strong>{`odel (DOM) DO NOT use header tags for anything other than defining html structural elements.`}</p>
    <p>{`Always specify sizes for h1 through h3.`}</p>
    <p>{`Font size beteen headers should be 2 points or more.`}</p>
    <p>{`Line height for headings, Golden ratios or 1.1 to 1.2 times font size?`}</p>
    <p>{`Define use of header tags in Call-Outs include rules for boxes' default background colors.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`<h1>Header</h1>`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      